<template>
  <div>
    <b-modal
      ref="modalDataAtiveCampaign"
      :cancel-title="$t('Fechar')"
      cancel-variant="outline-secondary"
      body-class=""
      :visible="false"
      :ok-only="true"
      :ok-title="$t('Continuar')"
      ok-variant="primary"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="confirmSaveModal"
      @cancel="hideModal"
    >
      <template slot="modal-title">
        {{ $t('Campanhas ativas') }}
        <b-badge
          class="ml-1"
          variant="danger"
        >
          {{ listCampanhas.length }}
        </b-badge>
      </template>

      <vue-perfect-scrollbar
        class="b-modal-scrollarea-form"
        :settings="configSettingsScroll"
      >
        <b-container
          class="pt-2 pb-0 pl-2 pr-2"
          fluid
        >
          <app-timeline
            v-if="listCampanhas.length > 0"
          >
            <app-timeline-item
              v-for="(row, index) in listCampanhas"
              :key="`campanha-item-${index}`"
              variant="success"
            >
              <div>
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{ row.nomeCampanha }}</h6>
                </div>

                <div
                  v-if="(row.idCamp !== '')"
                  class="d-flex flex-sm-row flex-column justify-content-between align-items-start mt-1"
                >
                  <b-media>
                    <h6>{{ row.idCamp }}</h6>
                  </b-media>
                </div>

                <p class="mt-1">
                  <template>
                    {{ $t('Próximo toque') }} - <b>{{ row.proximoToque }}</b>
                  </template>
                </p>

                <p class="mt-0">
                  <template>
                    {{ $t('Data do toque') }} - <b>{{ formateDate(row.dataProx) }}</b>
                  </template>
                </p>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  @click.stop.prevent="cancelCampanha(row.id, row.nomeCampanha)"
                >
                  {{ $t('Eliminar') }}
                </b-button>

              </div>
            </app-timeline-item>
          </app-timeline>
          <div
            v-if="listCampanhas.length === 0"
            class="no-results text-center"
            :class="{'show': !listCampanhas.length}"
          >
            <div>
              <b-alert
                class="mb-0 mt-1"
                variant="primary"
                show
              >
                <div class="alert-body">
                  <span>{{ $t('Nenhuma campanha ativa para este Lead/Contacto') }}</span>
                </div>
              </b-alert>
            </div>
          </div>
        </b-container>
      </vue-perfect-scrollbar></b-modal>
  </div>
</template>

<script>
import {
  BModal, BContainer, BMedia, BButton, BAlert, BBadge,
} from 'bootstrap-vue'
import '@/libs/sweet-alerts'
import { mapActions } from 'vuex'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { format, parseISO } from 'date-fns'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BModal,
    BContainer,
    BMedia,
    BButton,
    BAlert,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  mixins: [mixFormGeral],
  props: {
    data: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapActions('crm_leads_form', ['getCampanhas', 'setAssocCampanhaLead']),
    isDark() {
      return this.skin.value === 'dark'
    },
  },
  methods: {
    showModal() {
      return new Promise((resolve, reject) => {
        this.$refs.modalDataAtiveCampaign.show()
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    async hideModal() {
      this.resolvePromise(true)
      this.$refs.modalDataAtiveCampaign.hide()
    },
    formateDate(date) {
      return `${format(parseISO(date), 'yyyy-MM-dd')} ${format(parseISO(date), 'HH')}h${format(parseISO(date), 'mm')}`
    },
    async confirmSaveModal(evt) {
      evt.preventDefault()
      this.resolvePromise(true)
    },
    async cancelCampanha(idCamp, nameCamp) {
      this.$swal({
        title: '',
        html: `${this.$t('Tem a certeza que pretende cancelar o(s) envio(s) da campanha')} ${nameCamp} ${this.$t('para este Lead/Contacto?')}`,
        icon: 'info',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',

        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.dispatch('crm_leads_form/cancelCampanhaByLead', { sw221s02: btoa(idCamp) }).then(async resp => {
            if (resp === true) {
              await this.$store.dispatch('crm_leads_form/getCampanhasAtive').then(async res => {
                this.listCampanhas = res.campanhas || []
                this.totalCampanhas = res.total || 0
              }).catch(error => {
                throw error
              })
            }
          })
        }
      })
    },
  },
  setup(props) {
    const { skin } = useAppConfig()

    return {
      listCampanhas: props.data,
      totalCampanhas: props.total,
      skin,
      configSettingsScroll,
    }
  },
}
</script>
